import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import Button from '@mui/material/Button';
import CreateUser from "./createUser";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { delUserService, fetchUsersService } from "../api/api-service";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function DataTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [users, setUsers] = React.useState([]);
  const [modalState,setModalState]=React.useState(false);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchUsers = async () => {
    const users=await fetchUsersService();
    setUsers(users);
    console.log(users);
  };
  const handleDelete=async (username)=>{
     delUserService(username)
    .then((res)=>{
        console.log(res);
        let tempUser=users;
        const index=tempUser.findIndex((usr)=>usr.Username===username);
        tempUser.splice(index,1);
        setUsers([...tempUser]);
    });
  }
  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
        <div style={{ width:'100%',display:'flex',flexDirection:'row',justifyContent:'end',background:'#282c34'}}>
        <Button style={{marginRight:'5vh',marginBottom:'5vh'}}
         size="small" variant="outlined" 
         color="primary"
         onClick={()=>{setModalState(!modalState)}}
         >
                  ADD USER
                </Button>
                {modalState?<CreateUser modalState={modalState} setModalState={setModalState} users={users} setUsers={setUsers}/>:<></>}
        </div>
    <TableContainer
      sx={{ backgroundColor: "#1976d2", color: "white", width: "100vh" }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 500,
          background: "rgb(18 18 18 / 88%)",
          color: "white",
        }}
        aria-label="custom pagination table"
      >
        <TableBody>
          {users?.map((row,index) => (
            <TableRow key={index} sx={{ color: "white" }}>
              <TableCell
                component="th"
                sx={{ color: "rgb(255, 255, 255)", fontSize: "2vh" }}
                align="center"
                scope="row"
              >
                {row?.Username}
              </TableCell>
              <TableCell
                style={{
                  width: 160,
                  color: "rgb(255, 255, 255)",
                  fontSize: "2vh",
                }}
                align="right"
              >
                **********
              </TableCell>
              <TableCell
                style={{
                  width: 160,
                  fontSize: "3vh",
                  color: "#1fe61f",
                  fontSize: "3vh",
                }}
                align="right"
              >
                {row?.UserStatus=='CONFIRMED'?<ToggleOnIcon />:<ToggleOffIcon sx={{color:'red'}}/>}
              </TableCell>
              <TableCell
                style={{
                  width: 160,
                  fontSize: "3vh",
                  color: "#1fe61f",
                  fontSize: "3vh",
                }}
                align="left"
              >
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Button size="small" 
                variant="outlined"
                 color="error"
                 onClick={()=>{handleDelete(row?.Username)}}>
                  Delete
                </Button>
                <Button size="small" variant="outlined" color="secondary">
                  Edit
                </Button>

                </Box>
                
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={10} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx={{ color: "white" }}
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1,color:'white' }]}
              colSpan={4}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </>
  );
}

