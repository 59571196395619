import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import { useState } from 'react';
import ButtonAppBar from './components/navbar';
import DataTable from './components/users';
import Button from '@mui/material/Button';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInSide from './components/login';

function App() {
  const [users,setUsers]=useState();


  // const getUsers=async ()=>{
  //   const response = await fetch("https://cognito-cloudflare.pages.dev");
  //   const users = await response.json();
  //   setUsers(users);
  // }
  // useEffect(()=>{
  //   getUsers();
  // },[])

  return (
    <>
    {window.location.pathname==='/login'?<></>: <ButtonAppBar/>}
    <div className="App">

      <header className="App-header">
        
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<DataTable />}/>
         <Route path="/users" element={<DataTable />}/>
         <Route path="/login" element={<SignInSide />} />
    </Routes>
  </BrowserRouter>

      </header> 
     </div>
    </>

  );
}

export default App;
