// const url = "http://localhost:8787";
const url="https://ancient-bonus-6ad9.albanselmanaj1999.workers.dev";

export async function fetchUsersService() {
  try {
    let users = await fetch(`${url}/users`);
    users = await users.json();
    return users;
  } catch (error) {
    console.log(error);
  }
}

export async function addUserService(data) {
  console.log(data);

  fetch(`${url}/users`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function delUserService(username) {
  fetch(`${url}?user=${username}`, { method: "DELETE" })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}
