import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { addUserService } from '../api/api-service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  background:'rgb(18, 18, 18)'
};

export default function CreateUser({modalState,setModalState,users,setUsers}) {
  const [open, setOpen] = React.useState(true);
  const [user,setUser]=React.useState('');
  const [email,setEmail]=React.useState('');
  const handleClose = () => {
    setOpen(!modalState)
    setModalState(false);
  };
  const handleSubmit=async (e)=>{
    e.preventDefault();
    console.log('username:',user);
    console.log('password:',email);
    addUserService({user,email})
    .then((res)=>{
      console.log(res);
    });

  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <TextField value={user} 
        onChange={(e)=>setUser(e.target.value)}
        sx={{width:'100%',background:'#282c34',borderBottomColor:'white',
        input: {
            color: "white",
          }}} 
          id="filled-basic" label="Username" variant="filled" 
          InputLabelProps={{
            sx:{color:'#1976d2',borderBottomColor:'white'}
          }} />
        <TextField 
        value={email}
        onChange={(e)=>setEmail(e.target.value)}
        sx={{width:'100%',marginTop:'3vh',background:'#282c34',borderBottomColor:'white',
        input: {
            borderBottomColor:'white',
            color: "white",
          },
          fieldset: { borderColor: "red" }}} id="filled-basic" label="Email" variant="filled"
          InputLabelProps={{
            sx:{color:'#1976d2',borderBottomColor:'white'}
          }} />
          <Box sx={{display:'flex',marginTop:'3vh',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center'}}>
          <Button sx={{width:'50vh'}} 
          size='large' 
          variant="outlined"
          onClick={(e)=>{handleSubmit(e)}}
          >Create</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}